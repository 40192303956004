const FirebaseConfig = {
  apiKey: "AIzaSyD8U1_JICzD90woKp7D9sER99ff5WuMVMs",
  authDomain: "drhaven-ef273.firebaseapp.com",
  projectId: "drhaven-ef273",
  storageBucket: "drhaven-ef273.appspot.com",
  messagingSenderId: "580138233037",
  appId: "1:580138233037:web:b9f4e6dfccf4a12a7c0d84",
  measurementId: "G-G0PV6LP9L4",
};

export default FirebaseConfig;
